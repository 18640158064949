import type { ApolloError } from '@apollo/client';

export const contextualError = <T>(
  errorContext: string,
  error?: unknown,
  customProperties?: {
    message?: string;
    name?: string;
    data?: T;
  }
) => {
  if (typeof error === 'string') {
    error = new Error(error);
  }

  if (!(error instanceof Error) || !error?.message) {
    return;
  }

  const contextAwareError = new Error(
    `${errorContext}: ${customProperties?.message || error.message}`,
    error
  );
  contextAwareError.name = customProperties?.name || error.name;

  if (customProperties?.data) {
    const serializedData = JSON.stringify(customProperties.data, null, 4);
    contextAwareError.cause = error.cause
      ? { originalCause: error.cause, data: serializedData }
      : { data: serializedData };
  }

  return contextAwareError;
};

export const handleContextualError = (contextAwareError?: Error) => {
  if (!contextAwareError?.message) {
    return;
  }

  throw contextAwareError;
};

export const isApolloNotFoundError = (error: ApolloError) =>
  error.graphQLErrors.some((gqlError) =>
    Array.isArray(gqlError.extensions)
      ? gqlError.extensions.some((ex) => ex.response.status === 404)
      : (gqlError.extensions as { response?: { status?: number } })?.response
          ?.status === 404
  );

export const getErrorMessage = (error: unknown) => {
  return (
    (error instanceof Error && error.message) ||
    window.Hobbii?.errorMessages.unknownErrorOccurred ||
    ''
  );
};
